import axios, { AxiosRequestConfig } from "axios";

const baseURL = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const postMessage = async (inputText: string, sessionId: string) => {
  const config: AxiosRequestConfig = {
    baseURL: baseURL,
    method: "post",
    url: "/v1/api/dialog-flow/post-message",
    // @ts-ignore
    headers: {
      sessionId: sessionId,
    },
    data: {
      message: inputText,
    },
  };

  return await axios(config).then((res) => {
    return res.data;
  });
};

export const postAudioMessage = async (audioMessage: File, samepeRate: number, sessionId: string) => {
  const bodyFormData = new FormData();
  bodyFormData.append("audioFile", audioMessage);
  bodyFormData.append("samepeRate", String(samepeRate));
  const url = `${baseURL}/v1/api/dialog-flow/post-audio-message`;
  const result = await axios(url, {
    method: "POST",
    url,
    data: bodyFormData,
    headers: {
      "content-type": "multipart/form-data",
      sessionId: sessionId,
    },
  });
  return result;
};
