import { useContext, useEffect, useRef } from "react";
import { ChatPageContext } from "../../../contexts/chat-page.context";
import Chat from "../../../models/chat.model";

type TimeoutReturnType = ReturnType<typeof setTimeout>;

const SetTimoutComponent = ({ message }: { message: Chat }) => {
  const timeout = useRef<TimeoutReturnType>();
  const { handleSend } = useContext(ChatPageContext);

  useEffect(() => {
    if (message.customPayload.timeout) {
      timeout.current = setTimeout(
        () => {
          clearTimeout(timeout.current);
          handleSend("time-out", false);
        },
        Number(message?.customPayload?.timeout?.timeoutInMinutes * 60 * 1000)
        // Number(0.5 * 60 * 1000)
      );
    }
    return () => clearTimeout(timeout.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default SetTimoutComponent;
