import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Perfect from "../../assets/Icons/Perfect.svg";
import Good from "../../assets/Icons/Good.svg";
import Soso from "../../assets/Icons/Soso.svg";
import Bad from "../../assets/Icons/Bad.svg";
import Worse from "../../assets/Icons/Worse.svg";
import { ChatPageContext } from "../../contexts/chat-page.context";
import Chat from "../../models/chat.model";
import { postMessage } from "../../services/dispatcher.service";
interface ISelectedElement {
  icon: string;
  score: string;
}
interface ISatificationCard {
  options: ISelectedElement[];
  defaultValue?: ISelectedElement;
  currentSelected?: any;
  isEnable: boolean;
}

interface IIconList {
  [key: string]: string;
}

const ICON_LIST: IIconList = {
  "5": Perfect,
  "4": Good,
  "3": Soso,
  "2": Bad,
  "1": Worse,
};

const SatificationCard = ({
  options,
  currentSelected,
  isEnable,
}: ISatificationCard) => {
  const [selectedCsatResult, setSelectedCsatResult] = useState<string | null>(
    currentSelected
  );
  const { setIsLoading, appendChatMs, sessionIdRef } =
    useContext(ChatPageContext);

  useEffect(() => {
    setSelectedCsatResult(currentSelected);
  }, [currentSelected]);

  const handleSendSatificationFeedback = async (el: ISelectedElement) => {
    const customPayload = {
      text: el.score,
      customPayload: { type: "displaySatification", options: options, el },
    };
    try {
      setIsLoading(true);
      const message = `${el.score}  ${el.icon}`;

      const newChatArray: Chat[] = Chat.getBotObjects(customPayload, false);
      newChatArray.push(Chat.getUserObject(message));
      const chatResult = newChatArray.find((value) => {
        return value?.customPayload === undefined;
      });
      await appendChatMs([chatResult]);

      const inputText = `${el.score}`;
      const data = await postMessage(inputText, sessionIdRef?.current!);
      const newChatArray2: Chat[] = Chat.getBotObjects(data);
      await appendChatMs(newChatArray2);

      setIsLoading(false);
    } catch (error: any) {
      console.error(error);
      appendChatMs([Chat.addError(error)]);
      setIsLoading(false);
    }
    setSelectedCsatResult(el.score);
  };
  return (
    <SContainer>
      {options.map((el: ISelectedElement, index: number) => {
        return (
          <SContent
            key={index}
            className={`${isEnable ? "" : "block"} ${
              el.score.toString() === selectedCsatResult ? "enable" : "disable"
            }`}
          >
            <SIconButton
              onClick={(e: any) =>
                !selectedCsatResult &&
                isEnable &&
                handleSendSatificationFeedback(el)
              }
              className={selectedCsatResult || !isEnable ? "display-only" : ""}
            >
              <img src={`${ICON_LIST[el.score.toString()]}`} alt="icon" />
            </SIconButton>

            <SSCore>{el.score.toString()}</SSCore>
          </SContent>
        );
      })}
    </SContainer>
  );
};

export default SatificationCard;

const SContainer = styled.div`
  display: flex;
  font-family: ${(props) => props.theme.typography.fontStyle.regular};
  font-size: ${(props) => props.theme.typography.fontSize.medium};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  /* margin-left: 48px; */
  margin: 10px 0px 10px 48px;
`;
const SSCore = styled.p`
  margin: 0px;
  text-align: center;
`;

const SContent = styled.div`
  margin-right: 24px;
  &.block {
    opacity: 0.5;
    &.disable {
      opacity: 0.5;
    }
    &.enable {
      opacity: 1;
    }
  }
  &.enable {
    opacity: 1;
  }
`;

const SIconButton = styled.button`
  background-color: transparent;
  border: 0px solid transparent;
  cursor: pointer;
  padding: 0px;
  margin-bottom: 4px;
  &.display-only {
    cursor: default;
  }
`;
