const common = {
  palette: {
    common: {
      black: "#000",
      white: "#fff",
      borderGrey: "#F0F3F5",
      overlay: "rgba(0, 0, 0, 0.5)",
      error: "#b50000",
    },
    // User text box
    primary: {
      // main: "#70BDE9",
      main: "#EA765F",
      default: "#E3F0F8",
      textContrast: "#f4f4f4",
      avatarContrast: "#f0f0f0",
      violetIcon: "#B1A3CF",
    },
    // bot text box
    secondary: {
      main: "#70BDE9",
      dark: "#e6e6e6",
      darker: "#a2a2a2",
      textInput: "#f5f5f5",
      white: "#ffffff",
    },
  },
  typography: {
    lineHeight: {
      body: 0,
      title: 0,
    },
    fontSize: {
      small: "10px",
      medium: "14px",
      regular: "12px",
      locationTitle: "13px",
      big: "16px",
      title: "18px",
      main: "20px",
    },
    fontWeight: {
      light: 0,
      regular: 500,
      medium: 400,
      bold: 600,
    },
    fontColor: {
      black: "#000000",
      online: "#97c775",
      greenDark: "#389E80",
      redDark: "#D37474",
      main: "#404040",
      grey: " #888888",
      ligthGrey: " #9C9C9C",
      white: "#ffffff",
      darkGrey: "#394A60",
      onRecording: "#8298B4",
      default: "#2B78A1",
      darkBlue: "#1C274C",
      price: "#2B78A1",
    },
    fontStyle: {
      medium: "Urbanist-Medium",
      bold: "Urbanist-Bold",
      regular: "Urbanist-Regular",
      extraBold: "Urbanist-ExtraBold",
    },
  },
  borderRadius: {
    chatPanel: "30px",
    chatContent: "60px",
  },
  borderColor: {
    sky: "#ACCBE5",
  },
};

export default common;
