import React, { useState, useEffect, useRef, createContext } from "react";
import { Chat } from "../models/chat.model";
import { PostMessageResponse } from "../models/dispatcher.model";
import { postMessage, postAudioMessage } from "../services/dispatcher.service";

import generateSessionId from "../utils/generateSessionId";
import TimeUtil from "../utils/TimeUtil";

interface ChatPageContextProps {
  chatMs: Chat[];
  handleInit: () => {};
  resetConversation: () => {};
  handleSend: Function;
  scrollToBottom: Function;
  messagesEndRef: React.RefObject<HTMLDivElement> | null;
  specialComponent: string;
  setSpecialComponent: Function;
  // quickReplyContent: IComponentWithChoices;
  // menuSelectionsContent: IComponentWithChoices;
  isLoading: boolean;
  setIsLoading: Function;
  sessionIdRef: React.RefObject<string> | null;
  isManyPic: boolean;
  setIsManyPic: Function;
  isInitialised: boolean;
  audioFile: File | null;
  setAudioFile: Function;
  handleSendAudio: Function;
  microphoneState: string;
  setMicrophoneState: Function;
  audioChecker: string;
  setAudioChecker: Function;
  setIsAudioSending: Function;
  isAudioSending: boolean;
  appendChatMs: Function;
  updatedChatMs: Function;
}

export const ChatPageContext = createContext<ChatPageContextProps>({
  chatMs: [] as Chat[],
  resetConversation: Function,
  handleInit: Function,
  handleSend: Function,
  scrollToBottom: Function,
  messagesEndRef: null,
  specialComponent: "",
  setSpecialComponent: Function,
  // quickReplyContent: { choices: [] },
  // menuSelectionsContent: { choices: [] },
  isLoading: false,
  setIsLoading: Function,
  sessionIdRef: null,
  isManyPic: false,
  setIsManyPic: Function,
  isInitialised: false,
  audioFile: null,
  setAudioFile: Function,
  handleSendAudio: Function,
  microphoneState: "",
  setMicrophoneState: Function,
  audioChecker: "",
  setAudioChecker: Function,
  setIsAudioSending: Function,
  isAudioSending: false,
  appendChatMs: Function,
  updatedChatMs: Function,
});

interface ChatPageContextProviderProps {}

export const ChatPageContextProvider: React.FC<ChatPageContextProviderProps> = ({
  children,
}: any) => {
  const [isManyPic, setIsManyPic] = useState(false);
  const [chatMs, setChatMs] = useState<Chat[]>([]);
  const [isInitialised, setIsInitialised] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [specialComponent, setSpecialComponent] = useState<string>("");
  const [audioChecker, setAudioChecker] = useState("");
  // const [quickReplyContent, setQuickReplyContent] = useState<IComponentWithChoices>({
  //   choices: [],
  // });
  // const [menuSelectionsContent, setMenuSelectionsContent] = useState<IComponentWithChoices>({
  //   choices: [],
  // });

  const sessionIdRef = useRef<any>(generateSessionId() || "");
  const [microphoneState, setMicrophoneState] = useState("");
  const [audioFile, setAudioFile] = useState<any>();
  const [isAudioSending, setIsAudioSending] = useState(false);
  // useEffect(() => {
  //   console.info(`new sessionId: ${sessionIdRef.current}`);
  // }, [sessionIdRef]);

  useEffect(() => {
    scrollToBottom();
  }, [chatMs, isLoading]);

  const resetConversation = async () => {
    sessionIdRef.current = generateSessionId();
    setChatMs([]);
    handleInit();
  };
  const handleInit = async () => {
    try {
      const pathStrings = window.location.pathname.split("/");
      // make-an-appointment
      // find-a-clinic
      const path = pathStrings.find((p) => p);
      const data: PostMessageResponse = await postMessage(path || "Hello", sessionIdRef.current);

      if (data) {
        await appendChatMs(await Chat.getBotObjects(data));
        setIsInitialised(true);
        setIsLoading(false);
      }
    } catch (error: any) {
      console.error(error);
      setChatMs([Chat.addError(error)]);
      setIsLoading(false);
    }
  };

  const handleSend = async (inputText: string, shouldAppendInput = true) => {
    try {
      if (shouldAppendInput) {
        await appendChatMs([Chat.getUserObject(inputText)]);
      }
      setIsLoading(true);
      const data = await postMessage(inputText, sessionIdRef.current);

      if (data) {
        if (data.text) {
          const newChatArray: Chat[] = Chat.getBotObjects(data);
          await appendChatMs(newChatArray);
        }
      }

      setIsLoading(false);
    } catch (error: any) {
      console.error(error);
      appendChatMs([Chat.addError(error)]);
      setIsLoading(false);
    }
  };

  const handleSendAudio = async (audioFile: File, samepeRate: number) => {
    setIsAudioSending(true);
    try {
      const data = await postAudioMessage(audioFile, samepeRate, sessionIdRef.current);
      const resolvedInput = data.data.resolvedInput;
      await appendChatMs([Chat.getUserObject(resolvedInput)]);
      setIsLoading(true);
      if (data) {
        const newChatArray: Chat[] = Chat.getBotObjects(data.data);
        await appendChatMs(newChatArray);
      }
      if (data.status === 200) {
        setIsAudioSending(false);
        setAudioFile(null);
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsAudioSending(false);
      console.error(error);
      appendChatMs([Chat.addError(error)]);
      setIsLoading(false);
    }
  };
  const updatedChatMs = (newChatMs: Chat[]) => {
    setChatMs([...newChatMs]);
  };
  const appendChatMs = async (newChatMs: Chat[]) => {
    for (let i = 0; i < newChatMs.length; i++) {
      if (i === 0 || !newChatMs[i].isText()) {
        setChatMs((chatMs) => [...chatMs, newChatMs[i]]);
      } else {
        setIsLoading(true);
        await TimeUtil.delay(100);
        setIsLoading(false);
        await setChatMs((chatMs) => [...chatMs, newChatMs[i]]);
      }
    }
  };

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <ChatPageContext.Provider
      value={{
        chatMs,
        resetConversation,
        handleInit,
        handleSend,
        scrollToBottom,
        messagesEndRef,
        specialComponent,
        setSpecialComponent,
        // quickReplyContent,
        // menuSelectionsContent,
        isLoading,
        setIsLoading,
        sessionIdRef,
        isManyPic,
        setIsManyPic,
        isInitialised,
        audioFile,
        setAudioFile,
        handleSendAudio,
        microphoneState,
        setMicrophoneState,
        audioChecker,
        setAudioChecker,
        setIsAudioSending,
        isAudioSending,
        appendChatMs,
        updatedChatMs,
      }}
    >
      {children}
    </ChatPageContext.Provider>
  );
};
