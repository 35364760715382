import React from "react";
import SatificationCard from "../../card/SatificationCard";

const SelectedSatisficationFeedback = ({
  options,
  currentSelected,
  isEnable,
}: any) => {
  return (
    <SatificationCard
      options={options}
      currentSelected={currentSelected}
      isEnable={isEnable}
    />
  );
};

export default SelectedSatisficationFeedback;
