import React, { useContext, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";

import Chat from "../models/chat.model";
import { TogglePanelContext } from "../contexts/toggle-panel.context";
import { ChatPageContext } from "../contexts/chat-page.context";
import ChatEl from "./conversations/ChatEl";
import InputBar from "./InputBar";
import Screen from "./Screen";
import { playAudio, stopAudio } from "../utils/audio/audio";
import BotFloatingButton from "../assets/Icons/BotFloatingButton.svg";
import MinusSign from "../assets/Icons/MinusSign.svg";
import ResetIcon from "../assets/Icons/ResetIcon.svg";
import { MessageHelper, SelectorHelper } from "./MessageHelper";
import { AdditionalMessageHelper } from "./AdditionalMessageHelper";
import GooglemapEmbeded from "./list-selector/GooglemapEmbeded";

const ChatPage: React.FC = () => {
  const { handleToggleOpen } = useContext(TogglePanelContext);
  const isInitialisedRef = useRef(false);
  const {
    chatMs,
    resetConversation,
    handleInit,
    isInitialised,
    messagesEndRef,
    isLoading,
    sessionIdRef,
    setAudioChecker,
    updatedChatMs,
  } = useContext(ChatPageContext);
  useEffect(() => {
    if (!isInitialisedRef.current && !isInitialised) {
      handleInit();
      isInitialisedRef.current = true;
    }
    // eslint-disable-next-line
  }, [sessionIdRef]);

  // eslint-disable-next-line
  const loadingM = useMemo(() => Chat.getLoading(), []);
  const chatMsLength = useMemo(() => chatMs.length, [chatMs]);

  const onFinish = () => {
    setAudioChecker("finish");
  };

  const onStart = () => {
    setAudioChecker("start");
  };

  useEffect(() => {
    // chatMsLength
    if (chatMs && chatMsLength > 0) {
      const latestChatMs = chatMs[chatMsLength - 1];

      const prevSelectedIndex = latestChatMs.customPayload?.prevSelectedIndex;

      if (prevSelectedIndex) {
        let tempChatMs = [...chatMs];
        for (let i = chatMsLength - 1; i > 0; i--) {
          if (i === chatMsLength - 1) {
            continue;
          }
          if (chatMs[i].customPayload?.currentSelectedIndex === null) {
            tempChatMs[i].customPayload.currentSelectedIndex =
              prevSelectedIndex;
            updatedChatMs(tempChatMs);
            break;
          }
        }
      }

      const { outputAudio } = latestChatMs;
      if (outputAudio) {
        // @ts-ignore
        const bytes = new Uint8Array(outputAudio.data); // pass your byte response to this constructor
        const blob = new Blob([bytes], { type: "audio/mp3" }); // change resultByte to bytes

        const audioFile = new File([blob], `${new Date().getTime()}.mp3`, {
          // @ts-ignore
          type: "mp3",
          lastModified: Date.now(),
        });
        const recordedAudioUrl = URL.createObjectURL(audioFile);

        playAudio(recordedAudioUrl, onStart, onFinish);
        setAudioChecker("finish");
      }

      return () => {
        stopAudio();
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMsLength]);

  let latest = chatMs[chatMsLength - 1];
  return (
    <SChatPage>
      <SHeader>
        <SBotFloatingButton src={BotFloatingButton} alt="bot-icon" />
        <STitleContainer>
          <SHeaderText data-test="chatbot-header-text">PAM bot</SHeaderText>
          <SStatusContainer>
            <SCircle />
            <SStatus>ONLINE</SStatus>
          </SStatusContainer>
        </STitleContainer>

        <SToolbarAction>
          <SMinimizeIconContainer
            onClick={resetConversation}
            className={Screen.isMobile() ? "mobile" : ""}
          >
            <img src={ResetIcon} alt="" />
          </SMinimizeIconContainer>

          <SMinimizeIconContainer
            onClick={() => handleToggleOpen()}
            className={Screen.isMobile() ? "mobile" : ""}
          >
            <img src={MinusSign} alt="" />
          </SMinimizeIconContainer>
        </SToolbarAction>
      </SHeader>
      <SChatSection>
        <SChatContent>
          {chatMs.map((chatM, i) => {
            const { customPayload } = chatM;
            const isLatest = i === chatMs.length - 1;
            return (
              <React.Fragment key={`chatM-${i}`}>
                {customPayload?.type === "locationConfirmation" ? (
                  <>
                    <GooglemapEmbeded
                      embeddedUrl={customPayload.embedded_url}
                      addressDetail={customPayload.address_detail}
                    ></GooglemapEmbeded>
                    <ChatEl
                      isLatest={isLatest}
                      int={i}
                      chatM={chatM}
                      showAvatar={false}
                    />
                  </>
                ) : (
                  <>
                    <ChatEl
                      isLatest={isLatest}
                      int={i}
                      chatM={chatM}
                      showAvatar={chatM.showAvatar}
                    />
                    <SelectorHelper
                      isEnable={isLatest}
                      customPayload={customPayload}
                      // currentSelected={currentSelected}
                    ></SelectorHelper>
                  </>
                )}

                {/* {customPayload?.type === "locationSelector" && (
                  <LocationSelector customPayload={customPayload}></LocationSelector>
                )} */}
              </React.Fragment>
            );
          })}
          {isLoading && (
            <ChatEl
              isLatest={true}
              chatM={loadingM}
              showAvatar={loadingM.showAvatar}
            />
          )}
          <div ref={messagesEndRef} />
        </SChatContent>

        {latest && <MessageHelper type={latest}></MessageHelper>}
        {latest && (
          <AdditionalMessageHelper message={latest}></AdditionalMessageHelper>
        )}
      </SChatSection>
      <InputBar />
    </SChatPage>
  );
};

export default ChatPage;

const SChatPage = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  /* margin-top: 20px !important; */
`;

const SHeader = styled.div`
  height: 70px;
  /* margin-left: 0.8rem; */
  width: 100%;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 40px 40px 0px 0px;

  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  /* box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.5); */
  z-index: 2;

  @media (max-width: 767px) {
    @media (min-width: 360px) {
      height: 50px;
    }
  }
`;

const SHeaderText = styled.div`
  /* margin-left: 16px; */
  font-family: ${(props) => props.theme.typography.fontStyle.bold};
  font-size: ${(props) => props.theme.typography.fontSize.title};
  color: ${(props) => props.theme.typography.fontColor.grey};
  @media (max-width: 767px) {
    margin-left: 10px;

    @media (min-width: 360px) {
      font-size: 18px;
    }
  }
`;

const SToolbarAction = styled.div`
  margin-left: auto;
  margin-right: 1rem;
  gap: 0.5rem;
  display: flex;
  align-items: center;
`;
const SMinimizeIconContainer = styled.div`
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    transform: scale(1.2);
    transition: all 0.2s;
  }
  &.mobile {
    width: 30px;
    height: 30px;
    & > svg {
      width: 30px;
      height: 30px;
    }
  }
`;

const SChatSection = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
  overflow: hidden;
`;
const SChatContent = styled.div`
  background: white;
  overflow-x: hidden;
  height: 100%;
  &::-webkit-scrollbar {
    width: 2px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background: #e6e6e6;
  }
  @media (max-width: 767px) {
    border-radius: 32px 0 0 0;
  }
`;

const SBotFloatingButton = styled.img`
  width: 45px;
  margin-left: 20px;
`;

const STitleContainer = styled.div`
  margin-left: 10px;
`;

const SStatusContainer = styled.div`
  display: flex;
  align-items: center;
`;
const SCircle = styled.div`
  background-color: #97c775;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
`;

const SStatus = styled.p`
  color: ${(props) => props.theme.typography.fontColor.online};
  margin: 0px;
  font-size: ${(props) => props.theme.typography.fontSize.regular};
  font-family: ${(props) => props.theme.typography.fontStyle.bold};
`;
