import React from "react";
import { v4 as uuidv4 } from "uuid";

import RedirectComponent from "./message-component/input/RedirectComponent";
import Chat from "../models/chat.model";
import SetTimoutComponent from "./message-component/input/SetTimoutComponent";
import UrlRedirectComponent from "./message-component/input/UrlRedirectComponent";

export const AdditionalMessageHelper = ({ message }: { message: Chat }) => {
  if (message.customPayload === undefined) {
    return null;
  }

  const result = [];
  const uniqueId = uuidv4();
  if (message?.customPayload?.selectedLocation || message?.customPayload?.selectedPackage || message?.customPayload?.openUrlRedirect) {

    result.push(
      <RedirectComponent
        key="redirector-component"
        selectedOptionsName={
          message?.customPayload?.selectedLocation
            ? message?.customPayload?.selectedLocation
            :  message?.customPayload?.openUrlRedirect?
            message?.customPayload?.openUrlRedirect
            : message?.customPayload?.selectedPackage 
        }
      />
    );
  }
  if (message?.customPayload.type === "urlCardRedirector") {
    // Remove key with uniqueId to avoide duplicate redirect.
    result.push(<UrlRedirectComponent key={`url-card-redirect`} message={message} />);
  }
  if (message?.customPayload?.timeout) {
    result.push(<SetTimoutComponent key="set-timeout-component" message={message} />);
  }

  return <>{result.map((r) => r)}</>;
};