import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { ChatPageContext } from "../../contexts/chat-page.context";

interface ISelectorCard {
  contentList: ISelector[];
  selectedOptionByVoice: number | undefined;
  currentSelected?: any;
  isEnable?: boolean;
}
interface ISelector {
  id: string;
  number: number;
  url: string;
  title: string;
  content: string;
  topRightTitle: JSX.Element;
  distanceString: JSX.Element;
}

const SelectorCard: React.FC<ISelectorCard> = ({
  contentList,
  currentSelected,
  isEnable = true,
}) => {
  const { handleSend } = useContext(ChatPageContext);
  const [selectedSelector, setselectedSelector] = useState<
    number | null | undefined
  >(currentSelected);

  useEffect(() => {
    if (currentSelected) {
      setselectedSelector(parseInt(currentSelected));
    }
  }, [currentSelected]);

  const onClickHandler = (element: ISelector) => {
    if (!selectedSelector) {
      setselectedSelector(element.number);
      handleSend(`${element.number}. ${element.title}`);
    }
  };

  const isSelected = selectedSelector ? true : false;

  return (
    <>
      {contentList.map((el: ISelector, index: number) => {
        return (
          <SSelectorContainer
            key={index}
            className={`${isEnable ? "" : "disable"} ${
              isSelected ? "selected" : ""
            } ${
              !selectedSelector || selectedSelector === el.number ? "" : "faded"
            }`}
          >
            <SSelectorList
              className={`${
                selectedSelector === el.number ? "blue-selected" : ""
              } ${isEnable ? "" : "disable"}`}
              onClick={() => isEnable && onClickHandler(el)}
              value={el.title}
            >
              <StitleContainer>
                <SSelectorTitle>
                  {el.number ? `${el.number}. ` : ""}
                  {el.title}
                </SSelectorTitle>
                {el.topRightTitle}
              </StitleContainer>
              <Scontent>
                <SSelectorDetail>{el.content}</SSelectorDetail>
              </Scontent>
            </SSelectorList>
          </SSelectorContainer>
        );
      })}
    </>
  );
};

export default SelectorCard;

const SSelectorContainer = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
  &.disable {
    opacity: 0.5;
    cursor: default;
    &.selected {
      opacity: 1;
    }
    &.faded {
      opacity: 0.5;
    }
  }
  &.selected {
    li {
      cursor: default;
    }
  }
  &.faded {
    opacity: 0.5;
  }
`;

const SSelectorList = styled.li`
  border: 2px solid #edf2f7;
  border-radius: 16px;
  margin: 12px 16px;
  padding: 12px 16px;
  cursor: pointer;
  &.disable {
    cursor: default;
  }
  &.blue-selected {
    border: 2px solid #accbe5;
  }
  &.bot {
    margin: 5px 8px 8px;
    max-width: 240px;
  }
`;

const StitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SSelectorTitle = styled.div`
  color: ${(props) => props.theme.typography.fontColor.darkBlue};
  font-family: ${(props) => props.theme.typography.fontStyle.medium};
  font-size: ${(props) => props.theme.typography.fontSize.locationTitle};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  max-width: 286px;
  margin: 0px;
  word-wrap: break-word;
`;

const Scontent = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 6px;
  /* margin: 5px; */
`;

const SSelectorDetail = styled.div`
  font-family: ${(props) => props.theme.typography.fontStyle.regular};
  font-size: ${(props) => props.theme.typography.fontSize.small};
  font-size: 10px;
  color: ${(props) => props.theme.typography.fontColor.grey};
  margin: 0px 0px 0px 6px;
  max-width: 260px;

  /* &.screening-package {
    margin: 0px;
  } */
`;