import React from "react";

interface ITransparentPlate {
  children: React.ReactNode;
  isEnable?: boolean;
}

const TransparentPlate = ({ children, isEnable }: ITransparentPlate) => (
  <div style={{ position: "relative" }}>
    {children}
    {!isEnable && (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          pointerEvents: "auto",
        }}
      />
    )}
  </div>
);

export default TransparentPlate;
