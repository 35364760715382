import React from "react";
import styled from "styled-components";
import Chat from "../../models/chat.model";

interface TextBubbleProps {
  text: String;
  showAvatar: boolean;
  isFromBot: boolean;
  children?: JSX.Element;
}

const TextBubble: React.FC<TextBubbleProps> = ({
  text,
  isFromBot,
  showAvatar,
  children,
}) => {
  const className1 = isFromBot ? "from-bot" : "from-user";
  const className2 = showAvatar ? "" : "hide-avatar";
  return (
    <>
      {text !== "" && (
        <STextContainer
          className={`${className1} ${className2}`}
          data-test="bubble-response"
        >
          <>
            <>{text}</>
            {children}
          </>
        </STextContainer>
      )}
    </>
  );
};

export default TextBubble;
export const SBubbleContainer = styled.div`
  display: table-row;
`;
export const STextContainer = styled.div`
  text-align: left !important;
  line-height: 1.3;
  /* margin-top: 20px; */
  margin-left: 8px;
  max-width: 70%;
  padding: 0.5rem 0.8rem;
  font-family: ${(props) => props.theme.typography.fontStyle.regular};
  font-size: 13px;
  white-space: pre-line;
  // overflow-wrap: anywhere;
  &.from-bot {
    background: #f4f4f4;
    border-radius: 18px;
    color: ${(props) => props.theme.typography.fontColor.main};
  }
  &.hide-avatar {
    margin-left: 43px;
  }
  &.from-user {
    background: ${(props) => props.theme.palette.primary.main};
    word-wrap: break-word;
    color: white;
    border-radius: 1rem 1rem 0.1rem 1rem;
    margin-left: auto;
    margin-right: 1rem;
  }
  @media (max-width: 767px) {
    @media (min-width: 360px) {
      font-size: 16px;
    }
  }
`;
