import React, { useContext } from "react";
import styled from "styled-components";
import { ChatPageContext } from "../../../contexts/chat-page.context";
interface ISingleSelection {
  options?: { value: string }[];
  optionsTitle?: string;
}

// : React.FC<TextBubbleProps> = ({ chatM, showAvatar })
const SingleSelection: React.FC<ISingleSelection> = ({
  options,
  optionsTitle = "Select an option",
}) => {
  const { handleSend } = useContext(ChatPageContext);
  if (!options) {
    return null;
  }
  return (
    <SSingleSelectionContainer>
      <SText>{optionsTitle}</SText>
      <SButtonContainer>
        {options.map((el, index) => {
          return (
            <SButton
              key={index}
              value={el.value}
              onClick={(e: any) => handleSend(e.target.value)}
            >
              {el.value}
            </SButton>
          );
        })}
      </SButtonContainer>
    </SSingleSelectionContainer>
  );
};

export default SingleSelection;

const SSingleSelectionContainer = styled.div`
  margin: 0px 0px 0px 32px;
`;
const SText = styled.div`
  color: ${(props) => props.theme.typography.fontColor.onRecording};
  font-family: ${(props) => props.theme.typography.fontStyle.regular};
  font-size: ${(props) => props.theme.typography.fontSize.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};
  text-align: start;

  margin: 0px 0px 10px 15px;
`;

const SButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SButton = styled.button`
  background: ${(props) => props.theme.palette.primary.default};
  border: transparent;
  box-shadow: 0px 3px 10px rgba(130, 152, 180, 0.16);
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.typography.fontColor.default};
  min-width: 65px;
  height: 36px;
  margin-left: 10px;
  margin-bottom: 10px;
  font-family: ${(props) => props.theme.typography.fontStyle.regular};
  font-size: ${(props) => props.theme.typography.fontSize.medium};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  cursor: pointer;
  padding: 10px;
`;
