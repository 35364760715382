import React, { useState, createContext } from "react";

interface TogglePanelContextProps {
  isOpen: boolean;
  handleToggleOpen: Function;
}

interface ITogglePanelContextProvider {
  children: React.ReactNode;
}
export const TogglePanelContext = createContext<TogglePanelContextProps>({
  isOpen: false,
  handleToggleOpen: Function,
});

const TogglePanelContextProvider: React.FC<ITogglePanelContextProvider> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <TogglePanelContext.Provider value={{ isOpen, handleToggleOpen }}>
      {children}
    </TogglePanelContext.Provider>
  );
};

export default TogglePanelContextProvider;
