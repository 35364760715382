import React from "react";
import styled from "styled-components";
import SelectorCard from "../card/SelectorCard";
interface ILocationSelectorList {
  locationJsonString: string;
  currentSelected: any;
  isEnable: boolean;
}
const LocationSelector = ({
  locationJsonString,
  currentSelected,
  isEnable,
}: ILocationSelectorList) => {
  const locationList = JSON.parse(locationJsonString);
  const resultLocationList = locationList.map((el: any) => {
    return {
      id: el.id,
      number: el.number,
      url: el.url,
      title: el.name,
      content: el.address,
      topRightTitle :<SDistance>{el.distanceString}</SDistance> 
    };
  });

  return (
    <SContainer>
      <STitle>Select a clinic location</STitle>

      <SContent>
        <SelectorCard
          isEnable={isEnable}
          contentList={resultLocationList}
          selectedOptionByVoice={0}
          currentSelected={currentSelected}
        ></SelectorCard>
      </SContent>
    </SContainer>
  );
};

export default LocationSelector;

const SContainer = styled.div`
  background: #ffffff;
  /* Border/border */

  border: 1px solid #edf2f7;
  border-radius: 20px;
  margin: 16px 16px 16px 40px;
  max-width: 304px;
`;

const STitle = styled.p`
  color: ${(props) => props.theme.typography.fontColor.onRecording};
  font-family: ${(props) => props.theme.typography.fontStyle.regular};
  font-size: ${(props) => props.theme.typography.fontSize.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};
  text-align: center;
  margin: 10px 0px 0px 0px;
  line-height: auto;
`;

const SContent = styled.div`
  padding: 12px, 16px, 12px, 16px;
`;

const SDistance = styled.div`
  display: flex;
  height: 16px;
  color: var(--connect-line, #3aa9fa);

  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
  font-size: 12px;
  font-family: ${(props) => props.theme.typography.fontStyle.regular};
  font-weight: 600;
  justify-content: flex-end;
  align-items: center;
`;