import React, { useState, useContext, useRef } from "react";
import styled from "styled-components";
// import DeleteIcon from "@mui/icons-material/Delete";
// import SendIcon from "@mui/icons-material/Send";
import MicButton from "../assets/Icons/MicButton.svg";
import { ChatPageContext } from "../contexts/chat-page.context";
import { blobToBase64, getFileFromBase64, stopAudio } from "../utils/audio/audio";
import { audioWavRecorder } from "../utils/audio/audioWavRecorder";
import DeleteIcon from "../assets/Icons/DeleteIcon.svg";
import SendIcon from "../assets/Icons/SendIcon.svg";
import AudioSoundWave from "./AudioSoundWave";
import Timer from "./Timer";
import TimeUtil from "../utils/TimeUtil";

/**
 * Audio length limit in minutes
 */
const AudioSender = () => {
  const [openMicrecordDialog, setOpenMicrecordDialog] = useState(false);
  const {
    setAudioFile,
    handleSendAudio,
    microphoneState,
    isAudioSending,
    setAudioChecker,
  } = useContext(ChatPageContext);
  const forDelete = useRef<boolean>();

  
  const recordingRef = useRef(false);


  const startRecording = async () => {
    await audioWavRecorder.start();
  };

  const stopRecording = async () => {
    const { blob, sampleRate }: any = await audioWavRecorder.stop();

    const base64String = await blobToBase64(blob);
    const audioFile = getFileFromBase64(base64String, `${new Date().getTime()}.wav`);
    handleSendAudio(audioFile, sampleRate);
  };

  const handleOpenMicRecord = async () => {
    stopAudio();
    setAudioChecker("finish");
    if (recordingRef.current) {
      recordingRef.current = false;
    } else {
      recordingRef.current = true;
    }

    if (recordingRef.current) {
      try {
        await startRecording();
      } catch (e) {
        console.error(e);
        alert(e)
        return;
      }
      
    } else {
      await TimeUtil.delay(800);
      handleStopRecording();
    }

    setOpenMicrecordDialog(!openMicrecordDialog);
  };

  const handleStopRecording = () => {
    forDelete.current = false;
    stopRecording();
    setOpenMicrecordDialog(!openMicrecordDialog);
  };

  const handleDeleteAudio = () => {
    forDelete.current = true;
    recordingRef.current = false;
    audioWavRecorder.cancel();
    setOpenMicrecordDialog(!openMicrecordDialog);
    setAudioFile(null);
  };

  const handleSendAndClear = async () => {
    handleStopRecording();
    recordingRef.current = false;
  };

  return (
    <SContainer>
      <SMicIcon
        className={isAudioSending ? "audio-loading" : ""}
        onClick={() => {
          handleOpenMicRecord();
        }}
      >
        <SMicButton src={MicButton} alt="mic-icon" />
      </SMicIcon>
      {/* 159px; */}
      {/* <SSendTimeExtensionIcon onClick={() => handleSubmitAudioFile()} /> */}
      <SAudioContainer>
        {openMicrecordDialog && microphoneState === "granted" && (
          <div>
            <SSendAudioContainer>
              <SAudioRecoder>
                <SText> On Recording</SText>
                <SAudioBox>
                  <SSoundWave>
                    <AudioSoundWave></AudioSoundWave>
                  </SSoundWave>
                  <Timer />
                  <SSoundWave className="inverse">
                    <AudioSoundWave></AudioSoundWave>
                  </SSoundWave>
                </SAudioBox>

                <SButtonContainer>
                  <SButton className="delete" onClick={() => handleDeleteAudio()}>
                    <SIcon src={DeleteIcon} />
                    <SButtonText>Delete</SButtonText>
                  </SButton>
                  <SButton className="send" onClick={() => handleSendAndClear()}>
                    <SIcon src={SendIcon} />
                    <SButtonText>Send</SButtonText>
                  </SButton>
                </SButtonContainer>
              </SAudioRecoder>
            </SSendAudioContainer>
          </div>
        )}
      </SAudioContainer>
    </SContainer>
  );
};

export default AudioSender;

const SContainer = styled.div`
  width: 60px;
  /* margin-left: 5px; */
`;

const SAudioContainer = styled.div``;

const SSendAudioContainer = styled.div`
  z-index: 2;
  position: absolute;
  top: -90px;
  left: 0px;
  width: 100%;
`;

const SAudioRecoder = styled.div`
  background: #ffffff;
  box-shadow: 0px -3px 20px 3px rgba(128, 189, 221, 0.2);
  border-radius: 40px;
  height: 159px;
  display: block;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSize.regular};
  font-family: ${(props) => props.theme.typography.fontStyle.medium};
`;
const SButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 20px;
`;

const SMicIcon = styled.div`
  cursor: pointer;
  &:hover {
    fill: ${(props) => props.theme.palette.primary.main};
    transition: all 200ms;
    transition-timing-function: ease-in-out;
    transform: scale(1.1);
    transition: transform 0.1s linear;
  }
  margin-right: 2px;
  &.audio-loading {
    cursor: default;
    pointer-events: none;

    &:hover {
      fill: ${(props) => props.theme.palette.primary.main};
      transition: all 200ms;
      /* transition-timing-function: ease-in-out; */
      transform: scale(1);
      transition: transform 0.1s linear;
    }
  }
`;

const SMicButton = styled.img`
  width: 40px;
`;

const SText = styled.p`
  text-align: center;
  padding: 10px 0px 5px 0px;
  color: ${(props) => props.theme.typography.fontColor.onRecording};
`;

const SButton = styled.div`
  color: ${(props) => props.theme.typography.fontColor.white};
  border-radius: 20px;
  width: 84px;
  height: 40px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  &.delete {
    background: #b50000;
  }
  &.send {
    background: #80bddd;
  }
`;

const SButtonText = styled.p`
  color: ${(props) => props.theme.typography.fontColor.white};
  margin-left: 5px;
`;

const SIcon = styled.img`
  width: 20px;
`;

const SAudioBox = styled.div`
  display: flex;
  justify-content: center;
  height: 20%;
  margin-right: 8px;
  align-items: center;
  padding-bottom: 5px;
`;

const SSoundWave = styled.div`
  display: flex;
  justify-content: center;
  &.inverse {
    transform: scaleX(-1);
  }
`;


