import React from "react";
import ThemeContextProvider from "./contexts/Theme";
import { ChatPageContextProvider } from "./contexts/chat-page.context";
import TogglePanelContextProvider from "./contexts/toggle-panel.context";
import EmbeddedPage from "./components/EmbeddedPage";
// dotenv.config();

const App: React.FC = () => {
  return (
    <ThemeContextProvider>
      <TogglePanelContextProvider>
        <ChatPageContextProvider>
          <EmbeddedPage />
        </ChatPageContextProvider>
      </TogglePanelContextProvider>
    </ThemeContextProvider>
  );
};

export default App;
