import React, { useContext } from "react";
import styled from "styled-components";
import Chat from "../../../models/chat.model";
import { ChatPageContext } from "../../../contexts/chat-page.context";
import { postMessage } from "../../../services/dispatcher.service";

interface IUrlCard {
  chatM: Chat;
  isLatest: boolean;
}

const UrlCard: React.FC<IUrlCard> = ({ chatM, isLatest }) => {
  const { cards } = chatM.customPayload;
  const { appendChatMs, sessionIdRef, setAudioChecker } =
    useContext(ChatPageContext);

  const onClickHandler = async (trackingMessage: string) => {
    if (trackingMessage) {
      const data = await postMessage(trackingMessage, sessionIdRef?.current!);
      const newChatArray2: Chat[] = Chat.getBotObjects(data);
      await appendChatMs(newChatArray2);
      setAudioChecker("finish");
    }
  };

  return (
    <>
      {cards.map((el: any, index: number) => {
        return (
          <SUrlCardContainer
            key={index}
            className={isLatest ? "" : "disable"}
            onClick={() => isLatest && onClickHandler(el.trackingMessage)}
          >
            <SUrlCardLinkContainer>{el.text}</SUrlCardLinkContainer>
          </SUrlCardContainer>
        );
      })}
    </>
  );
};

export default UrlCard;

const SUrlCardContainer = styled.div`
  background: ${(props) => props.theme.palette.secondary.main};
  color: white;
  padding: 12px 27px;
  margin-top: 10px;
  text-align: center;
  /* border: 1px solid black; */
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &.disable {
    opacity: 0.5;
    cursor: default;
  }
`;

const SUrlCardLinkContainer = styled.div`
  padding: 0px;
  margin-top: 0px;
  width: 100%;
  text-decoration: none;
  color: inherit;
`;
