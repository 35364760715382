import React, { useMemo, useState, useEffect, useContext } from "react";
import Correct from "../../assets/Icons/Correct.svg";
import { ChatPageContext } from "../../contexts/chat-page.context";
import styled from "styled-components";

export default function MultipleChoiceSelection({ options, title }: any) {
  const data = JSON.parse(options);
  const { handleSend } = useContext(ChatPageContext);

  const onClickHandler = () => {
    if (selectedChoices?.length != 0) {
      handleSend(selectedChoices.join(", ").trim());
    }
  };
  // Convert string of choices into array and merge all choices into a single array
  const allChoices = useMemo(() => {
    const choices = data.flatMap((item: any) => item.choice.split(","));
    // Remove duplicates
    return [...new Set(choices)];
  }, [data]);

  const [selectedChoices, setSelectedChoices] = useState<String[]>([]);
  const [availableChoices, setAvailableChoices] = useState(allChoices);
  // const [selectedNames, setSelectedNames] = useState([]);

  useEffect(() => {
    if (selectedChoices.length === 0) {
      setAvailableChoices(allChoices);
      // setSelectedNames([]);
    } else {
      const validNames: any = data
        .filter((item: any) =>
          selectedChoices.every((choice) =>
            item.choice.split(",").includes(choice)
          )
        )
        .map((item: any) => item.name);

      // setSelectedNames(validNames);

      const newAvailableChoices: any = validNames
        .flatMap((name: any) =>
          data.find((item: any) => item?.name === name).choice.split(",")
        )
        .filter(
          (choice: any, index: any, self: any) => self.indexOf(choice) === index
        ); // remove duplicates

      setAvailableChoices(newAvailableChoices);
    }
  }, [selectedChoices]);

  const handleClick = (choice: any) => {
    setSelectedChoices((prevSelectedChoices: any) =>
      prevSelectedChoices.includes(choice)
        ? prevSelectedChoices.filter((i: any) => i !== choice)
        : [...prevSelectedChoices, choice]
    );
  };
  return (
    <SMultipleChoiceSelectionContainer>
      <Stitle>{title}</Stitle>
      <SDescription>
        ** To align with our recommended package, please choose from the
        remaining options. **
      </SDescription>
      {allChoices.map((choice: any, index) => (
        <SChoice
          key={index}
          onClick={() => handleClick(choice)}
          disabled={!availableChoices.includes(choice)}
          className={
            !availableChoices.includes(choice) ? "disabled-selected" : ""
          }
        >
          {index + 1 + ". "}
          {choice}
          {selectedChoices.includes(choice) ? (
            <SCheckedChoiceBox>
              <SCorrectImg src={Correct} />
            </SCheckedChoiceBox>
          ) : availableChoices.includes(choice) ? (
            <SIdleChoice></SIdleChoice>
          ) : (
            <SDisabledChoice></SDisabledChoice>
          )}
        </SChoice>
      ))}
      <SSent
        onClick={() => onClickHandler()}
        disabled={selectedChoices?.length == 0}
      >
        Done
      </SSent>
    </SMultipleChoiceSelectionContainer>
  );
}

const SMultipleChoiceSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 16px;
  margin-left: 45.4px;
  margin-bottom: 8px;
  width: 225px;
  background: #f0f3f5;
  border-radius: 15px;
`;
const Stitle = styled.div`
  font-family: ${(props) => props.theme.typography.fontStyle.medium};
  color: #404040;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
`;
const SDescription = styled.div`
  font-family: ${(props) => props.theme.typography.fontStyle.medium};
  font-size: 11px;
  line-height: 12px;
  font-weight: 600;
  color: #2b78a1;
`;

const SChoice = styled.button`
  font-family: ${(props) => props.theme.typography.fontStyle.medium};
  padding: 2px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  background: none;
  border: none;
  color: #404040;
  &.disabled-selected {
    color: #999999;
  }
`;

const SCheckedChoiceBox = styled.div`
  display: flex;
  justify-content: center;
  height: 14px;
  width: 14px;
  background: linear-gradient(0deg, #0d99ff, #0d99ff),
    linear-gradient(0deg, #7fc6fa, #7fc6fa);
  border: 1px solid #7fc6fa;
  border-radius: 50%;
`;

const SCorrectImg = styled.img`
  display: block;
  margin: auto;
  margin-right: 0.2rem;
  height: 5.25px;
  width: 7.5px;
`;

const SIdleChoice = styled.div`
  height: 14px;
  width: 14px;
  background: #ffffff;
  border: 1px solid #c8d3da;
  border-radius: 50%;
`;

const SDisabledChoice = styled.div`
  height: 14px;
  width: 14px;
  background: #ffffff;
  border: 1px solid #c8d3da;
  border-radius: 50%;
`;

const SSent = styled.button`
  display: flex;
  justify-content: center;
  border: none;
  align-items: center;
  margin-top: 6px;
  width: 225px;
  height: 33px;
  cursor: pointer;
  background: #80bddd;
  border-radius: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
`;
