const TimeUtil = {
  delay: (milliseconds: number) => {
    return new Promise<void>((resolve) => {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        resolve();
      }, milliseconds);
    });
  },
}

export default TimeUtil;