import React, { useMemo, createContext } from "react";
import { ThemeProvider } from "styled-components";
import commonTheme from "./common";

interface ThemeProps {
  children?: React.ReactNode;
  mode?: string;
}

const DEFAULT_MODE = "default";

export const ThemeContext = createContext({
  mode: DEFAULT_MODE,
});

export const ThemeContextProvider: React.FC<ThemeProps> = ({ children, mode = DEFAULT_MODE }) => {
  const theme = useMemo(() => {
    return commonTheme;
  }, []);
  return (
    <ThemeContext.Provider value={{ mode }}>
      <div>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </div>
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
