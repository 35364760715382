import React from "react";
import styled, { keyframes } from "styled-components";
import { STextContainer } from "./TextBubble";

const DotsWave: React.FC = () => {
  return (
    <STextContainer className="from-bot" data-cy="dot">
      <Wave>
        <Dot />
        <Dot />
        <Dot />
      </Wave>
    </STextContainer>
  );
};

export default DotsWave;

const waveKf = keyframes`
0%, 60%, 100% {
  transform: initial;
}
30% {
  transform: translateY(-0.4rem);
}
`;

const Wave = styled.div`
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const Dot = styled.div`
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  margin-right: 0.2rem;
  background: black;
  animation: ${waveKf} 1.3s linear infinite;

  &:nth-child(2) {
    animation-delay: -1.1s;
  }

  &:nth-child(3) {
    animation-delay: -0.9s;
  }
`;
