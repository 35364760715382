import React from "react";
import styled from "styled-components";
import BotFloatingButton from "../../assets/Icons/BotFloatingButton.svg";

interface IGooglemapEmbeded {
  addressDetail: string;
  embeddedUrl: string;
}

const GooglemapEmbeded = ({ addressDetail, embeddedUrl }: IGooglemapEmbeded) => {
  return (
    <>
      <SMapContainer>
        <img src={BotFloatingButton} alt="bot-icon"></img>
        <SMapProtector>
          <SOverlay>Location preview</SOverlay>

          <iframe
            title="Google Map"
            height="160"
            style={{
              border: "0",
              zIndex: "-1",
              width: "261px",
              borderRadius: "20px",
              marginLeft: 10,
            }}
            loading="lazy"
            allowFullScreen
            referrerPolicy="no-referrer-when-downgrade"
            src={embeddedUrl}
          ></iframe>
        </SMapProtector>
      </SMapContainer>

      <SContainer>
        <SAddress>
          <h4>Address</h4>
          <p>{addressDetail}</p>
        </SAddress>
      </SContainer>
    </>
  );
};

export default GooglemapEmbeded;
const SMapContainer = styled.div`
  display: flex;
  width: 26.88px;
  height: 26.88px;
  img {
    margin-left: 10px;
  }
`;

const SContainer = styled.div`
  /* Border/border */

  margin: 150px 16px 16px 45px;
  max-width: 304px;
`;

const SAddress = styled.div`
  background-color: #e3f0f8;
  border-radius: 18px;
  max-height: 65px;
  max-width: 211px;
  padding: 10px;
  h4 {
    font-family: ${(props) => props.theme.typography.fontStyle.bold};
    font-size: ${(props) => props.theme.typography.fontSize.regular};
    color: ${(props) => props.theme.typography.fontColor.darkGrey};
    margin: 0px 0px 5px 5px;
  }
  p {
    font-family: ${(props) => props.theme.typography.fontStyle.medium};
    font-size: ${(props) => props.theme.typography.fontSize.regular};
    color: ${(props) => props.theme.typography.fontColor.grey};
    margin: 0px 0px 0px 5px;
  }
`;
const SMapProtector = styled.div`
  z-index: 10;
  background-color: transparent;
  position: relative;
  pointer-events: none;
  margin-bottom: 5px;
`;

const SOverlay = styled.div`
  position: absolute;
  top: 11px;
  bottom: 20px;
  left: 30px;
  right: 0px;
  width: 93px;
  height: 22px;
  background-color: white;
  font-family: ${(props) => props.theme.typography.fontStyle.medium};
  font-size: ${(props) => props.theme.typography.fontSize.regular};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1873e8;
  /* border-top-left-radius: 20px;
  border-top-right-radius: 20px; */
`;
