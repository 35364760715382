import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import SelectorCard from "../card/SelectorCard";
import { ChatPageContext } from "../../contexts/chat-page.context";

interface IScreeningPackageList {
  screeningPackageJsonString: string;
  currentSelected: any;
  isEnable: boolean;
}
const ScreeningPackage = ({ screeningPackageJsonString, currentSelected, isEnable }: IScreeningPackageList) => {
  const { chatMs } = useContext(ChatPageContext);

  const [selectedOptionByVoice, setSelectedOptionByVoice] = useState<number | undefined>();

  useEffect(() => {
    const selectedOption = chatMs.find(
      (screeningPackage) => screeningPackage?.customPayload?.selectedPackage
    );

    if (selectedOption) {
      // @ts-ignore
      const indexString = selectedOption.customPayload.selectedPackage.selectedIndex.split(" ")[0];
      const selectedIndex = Number(indexString);
      setSelectedOptionByVoice(selectedIndex);
    }
  }, [chatMs]);
  const screeningPackage = JSON.parse(screeningPackageJsonString);

  const screeningPackageList = screeningPackage.map((el: any) => {
    return {
      id: el.id,
      number: el.number,
      url: el.url,
      title: el.title,
      content: <SDescription>{el.content}</SDescription>,
      topRightTitle: <SPrice>${el.price} (with GST)</SPrice>,
    };
  });

  return (
    <SContainer>
      <STitle>Select the Health Screening packages</STitle>
      <SContent>
        <SelectorCard
          isEnable={isEnable}
          contentList={screeningPackageList}
          selectedOptionByVoice={selectedOptionByVoice}
          currentSelected={currentSelected}
        ></SelectorCard>
      </SContent>
    </SContainer>
  );
};

export default ScreeningPackage;
const SContainer = styled.div`
  background: #ffffff;
  /* Border/border */

  border: 1px solid #edf2f7;
  border-radius: 20px;
  margin: 16px 16px 16px 40px;
  max-width: 304px;
`;

const SPrice = styled.p`
  color: ${(props) => props.theme.typography.fontColor.price};
  font-family: ${(props) => props.theme.typography.fontStyle.bold};
  font-size: ${(props) => props.theme.typography.fontSize.regular};
  margin: 0px;
`;

const STitle = styled.p`
  color: ${(props) => props.theme.typography.fontColor.onRecording};
  font-family: ${(props) => props.theme.typography.fontStyle.regular};
  font-size: ${(props) => props.theme.typography.fontSize.regular};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};
  text-align: center;
  margin: 10px 0px 0px 0px;
  line-height: auto;
  `;

const SContent = styled.div`
  padding: 12px, 16px, 12px, 16px;
`;

const SDescription = styled.div`
  font-family: ${(props) => props.theme.typography.fontStyle.regular};
  font-size: ${(props) => props.theme.typography.fontSize.small};
  font-weight: ${(props) => props.theme.typography.fontWeight.light};
  text-align: left;
  width: 230px;
  word-wrap: break-word;
`;
