import { useEffect, useRef } from "react";
import Chat from "../../../models/chat.model";

type TimeoutReturnType = ReturnType<typeof setTimeout>;

export default function UrlRedirectComponent({ message }: { message: Chat }) {
  const timeout = useRef<TimeoutReturnType>();

  useEffect(() => {
    const { url, openLinkInSecond } = message?.customPayload;

    timeout.current = setTimeout(() => {
      clearTimeout(timeout.current);
      const link = document.createElement("a");
      link?.setAttribute("href", url);
      link?.setAttribute("target", "_blank");
      link?.click();
      // window.open(url, "_blank");
    }, Number(openLinkInSecond || 3) * 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}
