// @ts-nocheck

import TimeUtil from "../TimeUtil";
import Recorder from "./Recorder";
let rec = null;
let audioStream = null;

export const audioWavRecorder = {
  /** Start recording the audio
   * @returns {Promise} - returns a promise that resolves if audio recording successfully started
   */
  start: async function () {
    //Feature Detection
    if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      //Feature is not supported in browser
      //return a custom error
      return Promise.reject(
        new Error("mediaDevices API or getUserMedia method is not supported in this browser.")
      );
    } else {
      for (let retry = 0; retry <= 3; retry++) {
        try {
          //Feature is supported in browser
          //create an audio stream
          const stream = await navigator.mediaDevices
            .getUserMedia({ audio: true } /*of type MediaStreamConstraints*/);
          console.log(stream.getAudioTracks)
          audioStream = stream;
          const AudioContext = window.AudioContext || window.webkitAudioContext;
          const audioContext = new AudioContext();
          const input = audioContext.createMediaStreamSource(stream);

          /* Create the Recorder object and configure to record mono sound (1 channel) Recording 2 channels will double the file size */
          rec = new Recorder(input, {
            numChannels: 1,
          });
          //start the recording process
          rec.record();
          return;
        } catch (e) {
          console.error(`An error occurs, retry getting audio resource again. Number of retries (${retry})`, e);
          await TimeUtil.delay(500);
        }
      }

      // Cannot get the resource after retries.
      throw new Error("Unable to get the audio resource.");
      // return (
      //   navigator.mediaDevices
      //     .getUserMedia({ audio: true } /*of type MediaStreamConstraints*/)
      //     //returns a promise that resolves to the audio stream
      //     .then((stream) /*of type MediaStream*/ => {
      //       console.log(stream.getAudioTracks)
      //       audioStream = stream;
      //       const AudioContext = window.AudioContext || window.webkitAudioContext;
      //       const audioContext = new AudioContext();
      //       const input = audioContext.createMediaStreamSource(stream);

      //       /* Create the Recorder object and configure to record mono sound (1 channel) Recording 2 channels will double the file size */
      //       rec = new Recorder(input, {
      //         numChannels: 1,
      //       });
      //       //start the recording process
      //       rec.record();
      //     })
      //     .catch((e) => {
      //       console.error(e);
      //     })
      // );

      /* errors are not handled in the API because if its handled and the promise is chained, the .then after the catch will be executed*/
    }
  },
  /** Stop the started audio recording
   * @returns {Promise} - returns a promise that resolves to the audio as a blob file
   */
  stop: function () {
    //return a promise that would return the blob or URL of the recording
    return new Promise((resolve) => {
      rec?.stop(); //stop microphone access
      audioStream.getAudioTracks()[0].stop();
      //create the wav blob and pass it on to createDownloadLink
      rec.exportWAV((blob) => {
        const sampleRate = rec.context.sampleRate;
        rec = null;
        audioStream = null;
        resolve({ blob, sampleRate });
      });
    });
  },
  /** Cancel audio recording*/
  cancel: function () {
    rec.stop(); //stop microphone access
    audioStream.getAudioTracks()[0].stop();
    rec.clear();
    rec = null;
    audioStream = null;
  },
};
