import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";

export default function Timer() {
  const [timer, setTimer] = useState(0);
  const clear = () => {
    window.clearInterval(id ? id.current : 0);
    setTimer(0);
  };
  const id = useRef(0);

  // Timer on speaking
  let devidedMinutes = timer % (60 * 60);
  let minutes: Number = Math.floor(devidedMinutes / 60);
  let devidedSeconds = timer % 60;
  let seconds: Number = Math.ceil(devidedSeconds);

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time: number) => time + 1);
    }, 1000);

    return () => clear();
  }, []);

  return (
    <STimer>
      <>
        {minutes ? minutes : "00"} :
        {seconds ? (seconds < 10 ? "0" + seconds : seconds) : "00"}
      </>
    </STimer>
  )
}

const STimer = styled.div`
  display: flex;
  justify-content: center;
  /* z-index: 10; */
  color: ${(props) => props.theme.typography.fontColor.darkGrey};
  font-size: ${(props) => props.theme.typography.fontSize.main};
  font-weight: ${(props) => props.theme.typography.fontWeight.bold};
  white-space: nowrap;
`;