import React from "react";
import { useLottie } from "lottie-react";
import SoundWaveAnimation from "../assets/IHH-soundwave.json";
const style = {
  height: 40,
  with: 140,
};

const AudioSoundWave = () => {
  const options = {
    animationData: SoundWaveAnimation,
    loop: true,
  };

  const { View } = useLottie(options, style);

  return <>{View}</>;
};

export default AudioSoundWave;
