import { useEffect, useRef } from "react";

type TimeoutReturnType = ReturnType<typeof setTimeout>;

export default function RedirectComponent({ selectedOptionsName }: { selectedOptionsName: any }) {
  const timeout = useRef<TimeoutReturnType>();
  useEffect(() => {
    if (selectedOptionsName) {
      const { selectedOptionJsonString, selectedIndex, openLinkInSecond } = selectedOptionsName;
      const indexString = selectedIndex.split(" ")[0];

      let index = 0;
      const convertIndex = Number(indexString);
      if (!isNaN(convertIndex)) {
        index = convertIndex - 1;
      }

      const locationJson = JSON.parse(selectedOptionJsonString);
      timeout.current = setTimeout(() => {
        clearTimeout(timeout.current);

        const link = document.createElement("a");
        link?.setAttribute("href", locationJson[Math.min(index, locationJson.length - 1)].url);
        link?.setAttribute("target", "_blank");
        link?.click();
      }, Number(openLinkInSecond || 3) * 1000);
    }
    // return () => clearTimeout(timeout.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}
