import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";

import { TogglePanelContext } from "../contexts/toggle-panel.context";
import ChatPage from "./ChatPage";

import BotFloatingButton from "../assets/Icons/BotFloatingButton.svg";
import { ChatPageContext } from "../contexts/chat-page.context";

const EmbeddedPage: React.FC = () => {
  const { isOpen, handleToggleOpen } = useContext(TogglePanelContext);
  const { audioChecker } = useContext(ChatPageContext);
  let counter = useRef<number>(0);

  useEffect(() => {
    counter.current = counter.current + 1;
  }, [isOpen]);

  return (
    <>
      {/* {!isOpen && <SBackGround />} */}

      <SBody id="embeded-widget">
        <SRoot>
          {isOpen ? (
            <SPanel>
              <ChatPage />
            </SPanel>
          ) : null}
          {counter.current === 0 && (
            <SChatInit>
              <SGreetingContainer>
                <SBot src={BotFloatingButton} alt="bot-icon" />
                <SGreetingText>
                  <SGreetingTitle data-test="greeting-title">Hello 👋🏻 I’m PAM. </SGreetingTitle>
                  <SGreetingContent>
                    Click to speak to PAM our <br />
                    Patience Assistant Manager ✨
                  </SGreetingContent>
                </SGreetingText>
              </SGreetingContainer>
            </SChatInit>
          )}
          <SChatIconButton data-test="floating-icon">
            <SCircle className={audioChecker === "start" && isOpen ? "active" : "inactive"} />
            <SFloatingIcon
              onClick={() => handleToggleOpen()}
              src={BotFloatingButton}
              alt="bot-floating"
            />
          </SChatIconButton>
        </SRoot>
      </SBody>
    </>
  );
};

export default EmbeddedPage;
// const SBackGround = styled.div`
//   background: ${(props) => props.theme.palette.common.overlay};
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100%;
// `;
const SCircle = styled.div`
  &.active {
    width: 60px;
    height: 60px;
    z-index: -1;
    position: absolute;
    top: 530px;
    border: 2px solid ${(props) => props.theme.borderColor.sky};
    border-radius: 50%;
    -webkit-animation: pulsate 1s infinite;
    -moz-animation: pulsate 1s infinite;
    -o-animation: pulsate 1s infinite;
    animation: pulsate 1s infinite;
    @keyframes pulsate {
      0% {
        -webkit-transform: scale(1, 1);
        opacity: 1;
      }
      100% {
        -webkit-transform: scale(1.3, 1.3);
        opacity: 0;
      }
    }
  }
`;
const SChatInit = styled.div`
  margin-right: 64px;
  margin-bottom: -29px;
  width: 270px;
  height: 80px;
  border-radius: 40px 40px 0px 40px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  @media (max-width: 767px) {
    margin-right: 4.2rem;
    margin-bottom: 2.2rem;
  }
  @media (width: 812px) and (height: 375px) {
    margin-right: 4.2rem;
    margin-bottom: 2.2rem;
  }
`;
const SGreetingContainer = styled.div`
  display: flex !important;
`;
const SGreetingText = styled.div`
  margin: 10px 0px 0px 15px;
`;
const SBody = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
const SFloatingIcon = styled.img`
  /* width: 80px; */
  position: absolute;
`;
const SRoot = styled.div`
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 20;

  @media (max-width: 767px) {
    /* height: 100vh; */
    /* height: 100vh;
    width: 100vw; */
    bottom: 0;
    right: 0;
  }
  @media (width: 812px) and (height: 375px) {
    /* height: 100vh; */
    /* height: 100vh;
    width: 100vw; */
    bottom: 0;
    right: 0;
  }
`;

const SPanel = styled.div`
  position: relative;
  bottom: -20px;
  margin-right: 55px;
  width: 391px;
  height: 537px;
  border-radius: 40px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: ${(props) => props.theme.palette.common.white};
  opacity: 1;
  transition: all 0.2s ease-in-out;
  transform: scale(1);
  @media (max-width: 400px) {
    height: 100dvh;
    width: 100vw;
    position: absolute;
    margin-right: 0px;
  }
  @media (max-width: 767px) {
    position: fixed;
    margin-right: 0px;
    bottom: 0px;
    height: 100dvh;
    width: 100vw;
    border-radius: 0px;
    z-index: 2;
  }
  @media (width: 812px) and (height: 375px) {
    position: absolute;
    margin-right: 0px;
    bottom: 0px;
    height: 100dvh;
    width: 100vw;
    border-radius: 0px;
    z-index: 2;
  }
`;

const SChatIconButton = styled.div`
  border-radius: 50%;
  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;

  @media (max-width: 767px) {
    bottom: 10px;
    right: 14px;
    position: absolute;
  }
  @media (width: 812px) and (height: 375px) {
    bottom: 14px;
    right: 14px;
    position: absolute;
  }
`;

const SBot = styled.img`
  position: relative;
  margin-top: 10px;
  margin-left: 10px;
  width: 60px;
`;

const SGreetingTitle = styled.p`
  color: ${(props) => props.theme.typography.fontColor.grey};
  margin: 0px;
  font-family: ${(props) => props.theme.typography.fontStyle.medium};
`;

const SGreetingContent = styled.p`
  color: ${(props) => props.theme.typography.fontColor.ligthGrey};
  margin: 0px;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSize.regular};
  font-family: ${(props) => props.theme.typography.fontStyle.medium};
`;
