// import { Moment } from "moment";
import { PostMessageResponse, IComponentWithChoices } from "./dispatcher.model";
export declare type SpecialComponent = "MENU_SELECTION" | "QUICK_REPLY" | "RANGE_SLIDER" | "";

export interface ChatConfig {
  type?: ChatType;
  senderRole: SenderRole;
  // createdAt?: Moment;
  showAvatar: boolean;
  selectedCsat?: any;
}

export declare type ChatType =
  | ""
  | "LOADING"
  | "ERROR"
  | "TEXT"
  | "QUICK_REPLY"
  | "SINGLE_SELECTION"
  | "CAROUSEL"
  | "FILE_ATTACHMENT";

export declare type SenderRole = "BOT" | "USER" | "SATIFICATION";

export class Chat {
  text: string;
  menuSelection?: IComponentWithChoices;
  quickReply?: IComponentWithChoices;
  singleSelection?: IComponentWithChoices;
  webhookStatusCode?: number;
  type: ChatType;
  senderRole: SenderRole;
  showAvatar: boolean;
  outputAudio?: Buffer;
  customPayload?: any;
  selectedCsat?: any;
  constructor(params: PostMessageResponse, chatConfig: ChatConfig) {
    this.text = params.text || "";
    this.type = chatConfig.type || "";
    this.webhookStatusCode = params.webhookStatusCode;
    this.senderRole = chatConfig.senderRole;
    this.showAvatar = chatConfig.showAvatar;
    this.outputAudio = params.outputAudio;
    this.customPayload = params.customPayload;
    this.selectedCsat = chatConfig.selectedCsat;
  }

  static getLoading(): Chat {
    return new Chat({ text: "" }, { senderRole: "BOT", type: "LOADING", showAvatar: true });
  }

  isLoading(): boolean {
    return this.type === "LOADING";
  }

  isFromBot(): boolean {
    return this.senderRole === "BOT";
  }
  // isDisplaySatification(): boolean {
  //   return this.customPayload !== undefined && this.customPayload.type === "displaySatification";
  // }
  isUrl(): boolean {
    return this.customPayload !== undefined && this.customPayload.type === "urlCard";
  }

  isText(): boolean {
    if (
      // this.isQuickReply() ||
      // this.isSingleSelection() ||
      // this.isCarousel() ||
      // this.isFileAttachment() ||
      !this.text
    ) {
      return false;
    }
    return true;
  }

  static addError(error: string): Chat {
    return new Chat(
      {
        text: `${error}`,
      },
      {
        type: "ERROR",
        senderRole: "BOT",
        showAvatar: true,
      }
    );
  }

  static getUserObject(inputText: string): Chat {
    return new Chat(
      {
        text: inputText,
      },
      {
        type: "TEXT",
        senderRole: "USER",
        showAvatar: false,
      }
    );
  }

  static getSpecialComponent(data: PostMessageResponse): SpecialComponent {
    let specialComponent: SpecialComponent = "";
    if (data.menuSelection) {
      specialComponent = "MENU_SELECTION";
    } else if (data.quickReply) {
      specialComponent = "QUICK_REPLY";
    }
    return specialComponent;
  }

  static getBotObjects(data: PostMessageResponse, showAvatar = true): Chat[] {
    let chatArray: Chat[] = [];
    if (data.text === "" || data.outputAudio?.length === 0) {
      chatArray.push(
        new Chat(
          {
            text: "",
            customPayload: data.customPayload,
          },
          {
            senderRole: "BOT",
            showAvatar: false,
          }
        )
      );
    } else {
      chatArray.push(
        new Chat(
          {
            text: data.text,
            outputAudio: data.outputAudio,
            customPayload: data.customPayload,
          },
          {
            senderRole: "BOT",
            showAvatar: showAvatar,
          }
        )
      );
    }

    return chatArray;
  }
}

export default Chat;
