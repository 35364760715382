import React, { useState, useRef, useEffect, useContext } from "react";

import styled from "styled-components";
import AudioSender from "./AudioSender";
import { ChatPageContext } from "../contexts/chat-page.context";
import { stopAudio } from "../utils/audio/audio";
import SendButton from "../assets/Icons/SendButton.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
// @ts-ignore
navigator.getUserMedia =
  // @ts-ignore
  navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

const InputBar = () => {
  const [inputVal, setInputVal] = useState<string>("");
  const inputRef = useRef<any>(null);
  const audioMode = process.env.REACT_APP_CHAT_BOT_AUDIO;
  const { setAudioFile, handleSend, microphoneState, setMicrophoneState, isAudioSending } =
    useContext(ChatPageContext);

  const clearInputText = () => {
    setInputVal("");
  };
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleKeyPress = async (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSendAndClear();
      e.preventDefault();
      clearInputText();
    }
  };

  const handleSendAndClear = async () => {
    stopAudio();
    if (inputVal) {
      handleSend(inputVal);
    }
    setAudioFile(null);
    clearInputText();
  };

  const checkMicrophonePermission = async () => {
    await navigator.mediaDevices.getUserMedia({ audio: true });
    if (navigator.permissions) {
      // @ts-ignore
      const permissionStatus: any = await navigator.permissions.query({ name: "microphone" });
      setMicrophoneState(permissionStatus.state);
      if (permissionStatus.state === "denied") {
        setMicrophoneState(permissionStatus.state);
        alert(
          "Please allow your browser to record audio. You can do this from the browser settings"
        );
      }
    } else {
      setMicrophoneState("granted");
    }
  };

  useEffect(() => {
    if (!navigator.mediaDevices) {
      alert("Sorry, your browser does not support this feature.");
      return;
    }

    if (audioMode === "true") {
      checkMicrophonePermission();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SInputBar className={isAudioSending ? "audio-loading" : ""}>
        <SInputBox
          value={inputVal}
          onChange={(event: React.BaseSyntheticEvent) => setInputVal(event.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="type here ..."
          ref={inputRef}
        />

        {microphoneState === "granted" && <AudioSender></AudioSender>}
        <SSendButtonContainer
          className={inputVal ? "can-click" : "disable"}
          onClick={() => (inputVal ? handleSendAndClear() : null)}
        >
          <SSendButton
            className={inputVal ? "can-click" : "disable"}
            src={SendButton}
            alt="send-button"
          />
        </SSendButtonContainer>
      </SInputBar>
      {isAudioSending && (
        <Box sx={{ display: "flex", zIndex: 99, position: "absolute", left: "44%", top: "88%" }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default InputBar;

const SInputBar = styled.div`
  height: 80px;
  border-top-color: ${(props) => props.theme.palette.common.borderGrey};
  border-top-width: 1px;
  border-top-style: solid;
  border-radius: 0 0 0 ${(props) => props.theme.borderRadius.chatPanel};
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  bottom: 0;
  width: 100%;
  &.audio-loading {
    opacity: 0.5;
    pointer-events: none;
  }
  @media (max-width: 767px) {
    border-radius: 0;
    @media (min-width: 375px) {
      height: 5rem;
    }
  }
  @media (width: 812px) and (height: 375px) {
    border-radius: 0;
  }
`;

const SInputBox = styled.textarea.attrs({ placeholderTextColor: "white" })`
  width: 100%;
  height: 38px;
  vertical-align: middle;
  overflow-y: hidden;
  line-height: 38px;
  background: ${(props) => props.theme.palette.secondary.textInput};
  font-size: ${(props) => props.theme.typography.fontSize.regular};
  font-family: ${(props) => props.theme.typography.fontStyle.regular};

  margin: 0 0.2rem 0 0.8rem;
  padding-left: 16px;
  resize: none;
  outline: none;
  border: none;
  border-radius: 1.4rem;
  letter-spacing: 0.1px;
  ::placeholder {
    color: ${(props) => props.theme.palette.secondary.darker};
  }
  @media (max-width: 767px) {
    @media (min-width: 375px) {
      font-size: 16px;
      height: 42px;
      line-height: 42px;
    }
  }
  &.audio-loading {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
    &:hover {
      box-shadow: none;
    }
    &:focus {
      box-shadow: none;
    }
  }
`;
const SSendButtonContainer = styled.div`
  cursor: pointer;
  &.disable {
    cursor: default;
  }
`;
const SSendButton = styled.img`
  /* background-color: red; */
  width: 40px;
  margin-right: 10px;
  &.can-click {
    /* fill: ${(props) => props.theme.palette.primary.main}; */
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      transition: transform 0.1s linear;
      transition: all 200ms;
      transition-timing-function: ease-in-out;
    }
  }
  &.disable {
    opacity: 0.5;
    cursor: default;
  }
`;
// const SPaperPlaneIcon = styled(SendIcon)`
//   height: 2rem;
//   width: 2rem;
//   margin-right: 14px;
//   fill: ${(props) => props.theme.palette.secondary.dark};
//   &.can-click {
//     fill: ${(props) => props.theme.palette.primary.main};
//     cursor: pointer;
//     &:hover {
//       transform: scale(1.1);
//       transition: transform 0.1s linear;
//       transition: all 200ms;
//       transition-timing-function: ease-in-out;
//     }
//   }
// `;
