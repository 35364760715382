import PIXI_SOUND from "pixi-sound";

let sound: PIXI_SOUND.Sound;
export const playAudio = (audioUrl: string, onStart = () => {}, onFinish = () => {}) => {
  const promise = new Promise((resolve) => {
    try {
      sound = PIXI_SOUND.Sound.from({
        url: audioUrl,
        autoPlay: true,
        singleInstance: true,
        complete: () => {
          onFinish();
        },
        loaded: () => {
          onStart();
        },
      });
    } catch (e) {
      resolve(e);
    }
  });
  return promise;
};

export const stopAudio = () => {
  if (sound) {
    sound.stop();
  }
  PIXI_SOUND.stopAll();
};

export function blobToBase64(blob: any): Promise<any> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export function getFileFromBase64(string64: string, fileName: string) {
  const trimmedString = string64.replace("data:audio/wav;base64,", "");
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  const type = "audio/wav";
  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}
