class Screen {
  static mobileBreakpoint = "767px";

  static mobileBreakpointInt = parseInt(
    Screen.mobileBreakpoint.replace("px", ""),
    0
  );

  static isMobile() {
    return (
      window.innerWidth <= parseInt(Screen.mobileBreakpoint.slice(0, -2), 10)
    );
  }
}

export default Screen;
